


























































































































































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
// import accountModule from '@/store/modules/accountModule';
// import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
// import tasqsListModule from '@/store/modules/tasqsListModule';
// import metaDataModule from '@/store/modules/metaDataModule';
// import proceduresModule from '@/store/modules/proceduresModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import cellRender from '@/views/agGrid/CellRenderWorkTicket.js';
import tasqsListModule from '@/store/modules/tasqsListModule';

  @Component({
    components: {
      AppLayout: () => getComponent('common/AppLayout'),
      Multiselect,
      TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
      Dropdown: () => getComponent('common/Dropdown'),
      TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
      WellSignalsViewPopup: () => getComponent('tasqsCommon/WellSignalsViewPopup'),
      WorkTicketSidebar: () => getComponent('tasqsCommon/WorkTicketSidebar'),
      MessageToaster: () => getComponent('tasqsCommon/MessageToaster'),
      DateRangePicker,
      draggable,
      AgGridVue,
      cellRender,
    },
  })
export default class WorkTicketDetail extends Vue {
  get activeWorkTicket() {
    return tasqsListModule.activeWorkTicket;
  }

  loadingSidebar = false;

  showAddNewCustomDailyCost = false;

  workTicketStatus = 'Open'

  workTicket: any = {}

  selectedDailyCostDay: any = {};

  requestDetails = ''

  isUpdatingStatus = false;

  showMessageToaster = false;

  showWellSignalPopup = false;

  showSignalTasqTooltip = false;

  columnDefs: any = [];

  getRowNodeId: any = null;

  context: any = null;

gridApi: any = null;

gridColumnApi: any = null;

defaultColDef = {
  initialWidth: 250,
  sortable: true,
  filter: true,
  editable: true,
  autoHeight: true,
  cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
  resizable: true,
};

// methods
handleStatusChange() {
  this.startConfetti();
  this.workTicket.status = 'Complete';
}

dailyWorkTableData: any = [
  {
    timezone: new Date().toLocaleDateString(),
    predictionID: this.workTicket.id,
    twentyFourHourSummary: '',
    nextTwentyFourHourSummary: '',
    hoursSpentToday: null,
    dailyCostsTable: [],
  },
];

addNewCustomDailyCost(date) {
  const updatedDate = new Date(date).toLocaleDateString();
  this.showAddNewCustomDailyCost = false;
  if (this.dailyWorkTableData.find(((data) => data.timezone === updatedDate))) return;
  this.dailyWorkTableData.push({
    timezone: updatedDate,
    predictionID: this.workTicket.id,
    twentyFourHourSummary: '',
    nextTwentyFourHourSummary: '',
    hoursSpentToday: null,
    dailyCostsTable: [],
  });
}

cellClick(e) {
  // console.log(e);
}

addNewItemToDailyCostTable() {
  this.dailyWorkTableData.map((eachDayData) => {
    if (this.selectedDailyCostDay.timezone === eachDayData.timezone) {
      eachDayData.dailyCostsTable.push({
        vendor: '',
        costCodes: '',
        amount: '',
        notes: '',
      });
    }
    return eachDayData;
  });
}

async onGridReady(params) {
  this.gridApi = params.api;
  // this.rowData = [];
  this.columnDefs = [];
  this.gridColumnApi = params.columnApi;
  const columns = [];
  this.gridApi.hideOverlay();

  this.columnDefs = [
    {
      field: 'vendor',
      cellClass: 'noborder',
      cellStyle: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', lineHeight: 1.6, fontSize: '12px', fontWeight: '300', color: 'white',
      },
      sortable: true,
      filter: true,
      width: 250,
      colId: 'vendor',
      headerName: 'Vendors',
    },
    {
      field: 'costCodes',
      width: 150,
      colId: 'CostCodes',
      cellStyle: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', lineHeight: 1.6, fontSize: '12px', fontWeight: '300', color: 'white',
      },
      cellClass: 'noborder',
      headerName: 'Cost Codes',
    },
    {
      field: 'amount',
      width: 150,
      colId: 'amount',
      cellStyle: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', lineHeight: 1.6, fontSize: '12px', fontWeight: '300', color: 'white',
      },
      cellClass: 'noborder',
      headerName: 'Amount',
    },
    {
      field: 'notes',
      width: 580,
      colId: 'notes',
      cellStyle: {
        overflow: 'visible', lineHeight: 1.6, fontSize: '12px', fontWeight: '300', color: 'white', padding: '0px', margin: '0px', borderTop: 'none', borderRight: 'none', borderLeft: 'none', borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      },
      cellClass: 'noborder',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        maxLength: 1000,
      },
      cellEditorPopup: true,

      headerName: 'Notes',
    },
    {
      field: '',
      colId: 'actions',
      sortable: true,
      width: 100,
      pinned: 'right',
      cellClass: 'noborder',
      suppressToolPanel: true,
      resizable: false,
      cellStyle: {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal', borderTop: 'none', borderRight: 'none', borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
      },
      suppressRowTransform: true,

      editable: false,
      cellRendererFramework: 'cellRender',
      cellRendererParams: {
        clicked(field) {
          alert(`${field} was clicked`);
        },
      },
    },
  ];

  this.gridColumnApi.autoSizeColumns();
}

startConfetti() {
  this.showMessageToaster = true;
  // @ts-ignore
  this.$confetti.start();

  setTimeout(() => {
    // @ts-ignore
    this.$confetti.stop();
  }, (1500));
}

@Watch('activeWorkTicket', { immediate: true })
updateWorkTicket(data) {
  if (data) {
    this.loadingSidebar = true;
    this.workTicket = { ...data };
    this.$nextTick(() => {
      this.loadingSidebar = false;
    });
  } else {
    tasqsListModule.setActiveWorkTicket('');
  }
}

created() {
  this.prepareDailyCostData();
}

prepareDailyCostData() {
  this.selectedDailyCostDay = this.dailyWorkTableData[0];
}
}
